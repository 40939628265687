import { Routes } from "@angular/router";
import { AuthGuard } from "@auth0/auth0-angular";
import { AsmPermissionGuard } from "./core/guards/asm.guard";
import { Permissions } from "./shared/common/permissions";
import { ShipmentDetailsComponent } from "./shipments/details/details.component";
import { InboundShipmentsComponent } from "./shipments/inbound/inbound-shipments.component";
import { ShipmentListComponent } from "./shipments/list/shipments.component";
import { ShipmentQuoteDetailComponent } from "./shipments/quotes/details/quote-detail.component";
import { ShipmentQuoteListComponent } from "./shipments/quotes/list/shipment-quote-list.component";
import { RealtimeInboundShipmentsComponent } from "./shipments/realtime-inbound/realtime-inbound-shipments.component";
import { ShipmentImportComponent } from "./shipments/shipment-import/shipment-import.component";
import { ClaimsComponent } from "./claims/claims/claims.component";
import { QuoteScheduledComponent } from "./shipments/quotes/quote-scheduled/quote-scheduled.component";

export const routes: Routes = [
  // Default/Dashboard Routes (/)
  {
    path: "",
    canActivate: [AuthGuard],
    loadComponent: () => import("./dashboard/dashboard/dashboard.component").then(m => m.DashboardComponent),
  },
  { path: 'mailbox', loadChildren: () => import('./mailbox/mailbox.routes') },
  // Admin Routes (/admin)
  {
    path: "admin",
    canActivate: [AuthGuard, AsmPermissionGuard],
    //canActivateChild: [AuthGuard, AsmPermissionGuard],
    data: { permission: [Permissions.ADMIN_ALL] },
    loadChildren: () => [
      {
        path: "customers",
        loadComponent: () => import("./admin/customers/list/customers-list.component").then(m => m.CustomersListComponent)
      },
      {
        path: "customers/:id",
        canActivate: [AuthGuard, AsmPermissionGuard],
        loadComponent: () => import("./admin/customers/detail/customer-detail/customer-detail.component").then(m => m.CustomerDetailComponent),
        data: { permission: [Permissions.ADMIN_ALL] },
      },
      {
        path: "users",
        loadComponent: () => import("./admin/users/list/users.component").then(m => m.UsersComponent)
      },
      {
        path: "invoicing/generated",
        loadComponent: () => import("./admin/invoicing/runs/invoiceruns.component").then(m => m.InvoiceRunsComponent)
      },
      {
        path: "invoicing/invoice/:invoiceId",
        loadComponent: () => import("./admin/invoicing/batches/details/invoice-batch-detail.component").then(m => m.InvoiceBatchDetailComponent)
      },
      {
        path: "invoicing/approvals",
        loadComponent: () => import("./admin/invoicing/approvals/approvals.component").then(m => m.ApprovalsComponent)
      },
      {
        path: "nmfcs",
        loadComponent: () => import("./admin/nmfcs/list/nmfcs.component").then(m => m.NMFCsComponent)
      },
      {
        path: "pricing",
        loadComponent: () => import("./admin/pricing/pricing.component").then(m => m.PricingComponent),
        data: { permission: [Permissions.ADMIN_ALL, Permissions.ACCOUNTING_MANAGERATES] }
      },
      {
        path: "carrier-accounts",
        loadComponent: () => import("./admin/carrier-accounts/carrier-accounts.component").then(m => m.CarrierAccountsComponent)
      },
      {
        path: "bulk-custom-loads",
        loadComponent: () => import("./admin/bulk-custom-loads/bulk-custom-loads.component").then(m => m.BulkCustomLoadsComponent)
      },
      {
        path: "edi",
        loadComponent: () => import("./admin/edi/edi.component").then(m => m.EdiComponent)
      },
      {
        path: "user-summary",
        loadComponent: () => import("./user-summary/user-summary.component").then(m => m.UserSummaryComponent)
      },
      {
        path: "assets",
        loadComponent: () => import("./admin/assets/assets.component").then(m => m.AssetsComponent)
      }
    ],
  },

  // Carrier Routes (/carriers)
  {
    path: "carriers",
    canActivate: [AuthGuard],
    loadChildren: () =>
      [
        {
          path: "summary",
          loadComponent: () => import("./carriers/summary/carrier-summary.component").then(m => m.CarrierSummaryComponent)
        },
        {
          path: "lookup",
          loadComponent: () => import("./carriers/lookup/carrier-lookup.component").then(m => m.CarrierLookupComponent),
          canActivate: [AuthGuard, AsmPermissionGuard],
          data: { permission: [Permissions.ADMIN_ALL] },
        },
        {
          path: "accounts",
          loadComponent: () => import("./admin/carrier-accounts/carrier-accounts.component").then(m => m.CarrierAccountsComponent),
          canActivate: [AuthGuard, AsmPermissionGuard],
          data: {
            permission: [Permissions.ADMIN_ALL, Permissions.DEV_ALL],
          },
        },
        {
          path: "prepro-summary",
          loadComponent: () => import("./prepro/prepro-summary.component").then(m => m.PreProSummaryComponent),
          canActivate: [AuthGuard, AsmPermissionGuard],
          data: {
            permission: [Permissions.ADMIN_ALL, Permissions.DEV_ALL],
          },
        },
        {
          path: ":id",
          canActivate: [AuthGuard, AsmPermissionGuard],
          loadComponent: () => import("./carriers/detail/carrier-detail.component").then(m => m.CarrierDetailComponent),
          data: { permission: [Permissions.ADMIN_ALL] },
        },
      ],
  },

  // Claims Routes (/claims)
  {
    path: "claims",
    canActivate: [AuthGuard],
    loadChildren: () => [
      {
        path: "",
        loadComponent: () => import("./claims/claims/claims.component").then(m => m.ClaimsComponent),
      },
      {
        path: 'carrier/:carrierId/:title',
        component: ClaimsComponent
      },
      {
        path: ':id',
        loadComponent: () => import("./claims/detail/claim-detail.component").then(m => m.ClaimDetailComponent),
        canActivate: [AuthGuard, AsmPermissionGuard],
        data: { permission: [Permissions.ADMIN_ALL] }
      },
    ]
  },

  // Developer Routes (/developers)
  {
    path: "developers",
    canActivate: [AuthGuard, AsmPermissionGuard],
    //canActivateChild: [AuthGuard, AsmPermissionGuard],
    data: { permission: [Permissions.DEV_ALL] },
    loadChildren: () => [
      {
        path: "",
        loadComponent: () => import("./developers/developers.component").then(m => m.DevelopersComponent),
      },
      {
        path: "notifications",
        loadComponent: () => import("./developers/notifications/notifications.component").then(m => m.NotificationsComponent)
      },
      {
        path: "email-tester",
        loadComponent: () => import("./developers/email-tester/email-tester.component").then(m => m.EmailTesterComponent),
      },
      {
        path: "permissions",
        loadComponent: () => import("./developers/permissions/permissions.component").then(m => m.PermissionsComponent),
      },
      {
        path: "sql-extractor",
        loadComponent: () => import("./developers/sql-extractor/sql-extractor.component").then(m => m.SqlExtractorComponent),
      },
      {
        path: "api-logs",
        loadComponent: () => import("./developers/dev-api-logs/dev-api-logs.component").then(m => m.DevApiLogsComponent),
      },
    ],
  },

  // Locations Routes (/locations)
  {
    path: "locations",
    canActivate: [AuthGuard],
    loadChildren: () => [
      {
        path: "",
        loadComponent: () => import("./locations/list/locations.component").then(m => m.LocationsComponent),
      },
      {
        path: "import",
        loadComponent: () => import("./locations/import/locations.import.component").then(m => m.LocationsImportComponent)
      },
      {
        path: ":id",
        loadComponent: () => import("./locations/detail/location-detail.component").then(m => m.LocationDetailComponent)
      },
    ],
  },

  // My Profile Routes (/my-profile)
  {
    path: "my-profile",
    canActivate: [AuthGuard],
    loadComponent: () => import("./user-profile/user-profile.component").then(m => m.UserProfileComponent)
  },

  // Packages Routes (/admin/packages)
  {
    path: "admin/packages",
    canActivate: [AuthGuard],
    loadComponent: () => import("./packages/packages-list/packages.component").then(m => m.PackagesComponent)
  },

  // Reports Routes (/reports)
  {
    path: "reports",
    canActivate: [AuthGuard, AsmPermissionGuard], data: { permission: [Permissions.REPORTS_VIEW] },
    loadComponent: () => import("./reports/reports/reports.component").then(m => m.ReportsComponent)
  },

  // Shipments Routes (/shipments)
  {
    path: "shipments",
    canActivate: [AuthGuard],
    loadChildren: () => [
      {
        path: '',
        loadComponent: () => import("./shipments/list/shipments.component").then(m => m.ShipmentListComponent),
      },
      {
        path: '',
        loadComponent: () => import("./shipments/quotes/list/shipment-quote-list.component").then(m => m.ShipmentQuoteListComponent),
        outlet: "side"
      },
      {
        path: 'carrier/:carrierID/:title', component: ShipmentListComponent
      },
      {
        path: 'carrier/:carrierID/:title/:carrierPerformance', component: ShipmentListComponent
      },
      {
        path: 'lastDispatcher/:lastDispatcher', component: ShipmentListComponent
      },
      {
        path: 'originalDispatcher/:originalDispatcher', component: ShipmentListComponent
      },
      {
        path: 'traveldirection/:travelDirection', component: ShipmentListComponent
      },
      {
        path: 'currentstatus/:currentStatus', component: ShipmentListComponent
      },
      {
        path: 'performance/:performance', component: ShipmentListComponent
      },
      {
        path: 'expedited/:expedited-supplier', component: ShipmentListComponent
      },
      {
        path: 'delivery-day/:delivery-day', component: ShipmentListComponent
      },
      {
        path: 'tripStatus/:tripStatus', component: ShipmentListComponent
      },
      {
        path: 'serviceType/:serviceType', component: ShipmentListComponent
      },
      {
        path: 'drilldown-default', component: ShipmentListComponent
      },
      {
        path: 'createdBy/:createdBy', component: ShipmentListComponent
      },
      {
        path: 'assignedBy/:assignedBy', component: ShipmentListComponent
      },
      {
        path: 'dispatch-dashboard-drilldown/dispatch-meta/:dispatch-meta', component: ShipmentListComponent
      },
      {
        path: 'dispatch-dashboard-drilldown/dispatch-exact-date/:dispatch-start-date', component: ShipmentListComponent
      },
      {
        path: 'dispatch-dashboard-drilldown/dispatch-date-range/:dispatch-start-date/:dispatch-end-date', component: ShipmentListComponent
      },
      {
        path: 'dispatch-dashboard-drilldown/dispatch-meta/:dispatch-meta/customer/:customerId', component: ShipmentListComponent
      },
      {
        path: 'dispatch-dashboard-drilldown/dispatch-exact-date/:dispatch-start-date/customer/:customerId', component: ShipmentListComponent
      },
      {
        path: 'dispatch-dashboard-drilldown/dispatch-date-range/:dispatch-start-date/:dispatch-end-date/customer/:customerId', component: ShipmentListComponent
      },
      {
        path: 'customer/:customerId', component: ShipmentListComponent
      },
      {
        path: 'customer/:customerId/createdBy/:createdBy', component: ShipmentListComponent
      },
      {
        path: 'customer/:customerId/assignedBy/:assignedBy', component: ShipmentListComponent
      },
      {
        path: 'new', component: ShipmentQuoteDetailComponent, canActivate: [AuthGuard, AsmPermissionGuard], data: { permission: [Permissions.ADMIN_ALL, Permissions.SHIPMENTS_CREATE] }
      },
      {
        path: 'quotes', component: ShipmentQuoteListComponent, canActivate: [AuthGuard, AsmPermissionGuard], data: { permission: [Permissions.ADMIN_ALL, Permissions.SHIPMENTS_CREATE] }
      },
      {
        path: 'requested', component: ShipmentQuoteListComponent
      },
      {
        path: 'quotes/quick',
        loadComponent: () => import("./shipments/quotes/quick-quote/quick-quote.component").then(m => m.QuickQuoteComponent)
      },
      {
        path: 'quotes/zip',
        loadComponent: () => import("./shipments/quotes/zip-quote/zip-quote.component").then(m => m.ZipQuoteComponent)
      },
      {
        path: 'quotes/multi',
        loadComponent: () => import("./shipments/quotes/multi-quote/multi-quote.component").then(m => m.MultiQuoteComponent)
      },
      {
        path: 'quotes/multi/:id',
        loadComponent: () => import("./shipments/quotes/multi-quote/multi-quote.component").then(m => m.MultiQuoteComponent)
      },
      {
        path: 'quotes/:id/scheduled', component: QuoteScheduledComponent
      },
      {
        path: 'quotes/:id', component: ShipmentQuoteDetailComponent
      },
      {
        path: 'inbound', component: InboundShipmentsComponent
      },
      {
        path: 'realtime-inbound', component: RealtimeInboundShipmentsComponent
      },
      {
        path: 'inbound/filter/:carrierName/:date', component: InboundShipmentsComponent
      },
      {
        path: 'inbound/filter/:date', component: InboundShipmentsComponent
      },
      {
        path: 'realtime-inbound/filter/:date', component: RealtimeInboundShipmentsComponent
      },
      {
        path: 'import', component: ShipmentImportComponent, canActivate: [AuthGuard, AsmPermissionGuard], data: { permission: [Permissions.ADMIN_ALL, Permissions.SHIPMENTS_CREATE] }
      },
      {
        path: ':id',
        loadComponent: () => import("./shipments/details/details.component").then(m => m.ShipmentDetailsComponent)
      },
      {
        path: ':id/details', component: ShipmentDetailsComponent
      },
      {
        path: ':id/tracking', component: ShipmentDetailsComponent
      },
      {
        path: ':id/carrier-costs', component: ShipmentDetailsComponent, canActivate: [AuthGuard, AsmPermissionGuard], data: { permission: [Permissions.ADMIN_ALL] }
      },
      {
        path: ':id/customer-costs', component: ShipmentDetailsComponent//, canActivate: [AuthGuard, AsmPermissionGuard], data: { permission: [Permissions.ADMIN_ALL]}
      },
      {
        path: ':id/document-and-labels', component: ShipmentDetailsComponent
      },
      {
        path: ':id/notes', component: ShipmentDetailsComponent
      },
      {
        path: ':id/emails', component: ShipmentDetailsComponent
      },
      {
        path: ':id/audit-logs', component: ShipmentDetailsComponent
      },
      {
        path: ':id/api-logs', component: ShipmentDetailsComponent
      },
      {
        path: ':id/edi', component: ShipmentDetailsComponent
      },
      {
        path: ':id/contacts', component: ShipmentDetailsComponent
      },
      {
        path: ':id/lane-history', component: ShipmentDetailsComponent
      },
      {
        path: ':id/edit', component: ShipmentDetailsComponent
      }
    ]
  },

  // Catch-All / Not Found
  {
    path: '**',
    pathMatch: 'full',
    loadComponent: () => import("./page-not-found.component").then(m => m.PageNotFoundComponent)
  },
];
